




















































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { ExhibitionItemType } from '@type/exhibition';
import axios from 'axios';

@Component({
  components: {
  },
})

export default class ExhibitionItem extends Vue {
  private exhibition: ExhibitionItemType = null;

  private created() {
    this.fetch();
  }

  private async fetch() {
    try {
      const { data } = await axios.get(`${process.env.VUE_APP_URL}api/exhibition/item/${this.$route.params.id}`);
      this.exhibition = data;
    } catch (err) {
      alert('데이터 불러오기 실패');
    }
  }
}
