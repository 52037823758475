




















import { Component, Vue, Watch } from 'vue-property-decorator';
import { State, Getter, Mutation, Action, namespace } from 'vuex-class';
import Nav from '@/components/Nav.vue';
import Footer from '@/components/Footer.vue';

@Component({
  components: {
    Nav,
    Footer,
  }
})

export default class App extends Vue {
  private created() {
  }

}
