


































import { Component, Vue } from 'vue-property-decorator';
import { State, Getter, Mutation, Action } from 'vuex-class';
@Component({
  components: {
  },
})

export default class Footer extends Vue {
  private get isMobile() {
    return window.innerWidth <= 1040;
  }
}

