



























































































import { Component, Vue, Prop } from 'vue-property-decorator';
import {
  State, Getter, Mutation, Action,
} from 'vuex-class';
import { ObjectItemType } from '@type/object';
import axios from 'axios';

@Component({
  components: {
  },
})

export default class Objects extends Vue {
  private objects: ObjectItemType[] = [];

  private async created() {
    await this.fetch();
  }

  private async fetch() {
    try {
      const { data } = await axios.get(`${process.env.VUE_APP_URL}api/object/all`);
      this.objects = data;
    } catch (err) {
      alert('데이터 불러오기 실패');
    }
  }
}
