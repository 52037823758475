



























































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { ExhibitionItemType } from '@type/exhibition';
import axios from 'axios';

@Component({
  components: {
  },
})

export default class Exhibitions extends Vue {
  private exhibitions: ExhibitionItemType[] = [];

  private async created() {
    await this.fetch();
  }

  private async fetch() {
    try {
      const { data } = await axios.get(`${process.env.VUE_APP_URL}api/exhibition/all`);
      this.exhibitions = data;
    } catch (err) {
      alert('데이터 불러오기 실패');
    }
  }
}
