












































































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import {
  State, Getter, Mutation, Action,
} from 'vuex-class';

@Component({
})

export default class Nav extends Vue {
  private isShowMenu: boolean = false;

  private switchMenu() {
    this.isShowMenu = !this.isShowMenu;
  }
}
