














































import { Component, Vue, Prop } from 'vue-property-decorator';
import {
  State, Getter, Mutation, Action,
} from 'vuex-class';
import axios from 'axios';

@Component({
  components: {
  },
})

export default class Contact extends Vue {
  private contactText: string = '';

  private async created() {
    try {
      const { data } = await axios.get(`${process.env.VUE_APP_URL}api/contact`);
      this.contactText = data;
    } catch (err) {
      alert('데이터 불러오기 실패');
    }
  }
}
