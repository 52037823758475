



















































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import {
  State, Getter, Mutation, Action,
} from 'vuex-class';
import axios from 'axios';

@Component({
  components: {
  },
})

export default class All extends Vue {
  private artists: any[] = [];

  private exhibitions: any[] = [];

  private objects: any[] = [];

  private artBooks: any[] = [];

  private magazines: any[] = [];

  private async created() {
    await this.fetch();
  }

  private async fetch() {
    try {
      const { data } = await axios.get(`${process.env.VUE_APP_URL}api/all`);
      this.artists = data.artist;
      this.exhibitions = data.exhibition;
      this.objects = data.object;
      this.artBooks = data.artBook;
      this.magazines = data.magazine;
    } catch (err) {
      alert('데이터 불러오기 실패');
    }
  }
}
