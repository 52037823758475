import Vue from 'vue';
import Router from 'vue-router';
import Artists from './views/Artists.vue';
import ArtistItem from './views/ArtistItem.vue';
import Objects from './views/Objects.vue';
import ObjectItem from './views/ObjectItem.vue';
import Contact from './views/Contact.vue';
import Index from './views/Index.vue';
import ArtBooks from './views/ArtBooks.vue';
import ArtBookItem from './views/ArtBookItem.vue';
import Magazines from './views/Magazines.vue';
import MagazineItem from './views/MagazineItem.vue';
import Exhibitions from './views/Exhibitions.vue';
import ExhibitionItem from './views/ExhibitionItem.vue';
import All from './views/All.vue';

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'index',
      component: Index,
    },
    {
      path: '/objects',
      name: 'objects',
      component: Objects,
    },
    {
      path: '/object/:id',
      name: 'objectItem',
      component: ObjectItem,
    },
    {
      path: '/artists',
      name: 'artists',
      component: Artists,
    },
    {
      path: '/artist/:id',
      name: 'artistItem',
      component: ArtistItem,
    },
    {
      path: '/contact',
      name: 'contact',
      component: Contact,
    },
    {
      path: '/artbooks',
      name: 'artBooks',
      component: ArtBooks,
    },
    {
      path: '/artbook/:id',
      name: 'artBookItem',
      component: ArtBookItem,
    },
    {
      path: '/exhibitions',
      name: 'exhibitions',
      component: Exhibitions,
    },
    {
      path: '/exhibition/:id',
      name: 'exhibitionItem',
      component: ExhibitionItem,
    },
    {
      path: '/magazines',
      name: 'magazines',
      component: Magazines,
    },
    {
      path: '/magazine/:id',
      name: 'magazineItem',
      component: MagazineItem,
    },
    {
      path: '/all',
      name: 'all',
      component: All,
    },
  ],
});
