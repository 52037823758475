




















































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import {
  State, Getter, Mutation, Action,
} from 'vuex-class';
import { ArtistItemType } from '@type/artist';
import axios from 'axios';

@Component({
  components: {
  },
})

export default class ArtistItem extends Vue {
  private artist: ArtistItemType = null;

  private created() {
    this.fetch();
  }

  private async fetch() {
    try {
      const { data } = await axios.get(`${process.env.VUE_APP_URL}api/artist/item/${this.$route.params.id}`);
      this.artist = data;
    } catch (err) {
      alert('데이터 불러오기 실패');
    }
  }
}
