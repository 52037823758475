




















































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { ArtBookItemType } from '@type/artbook';
import axios from 'axios';

@Component({
  components: {
  },
})

export default class ArtBookItem extends Vue {
  private artBook: ArtBookItemType = null;

  private created() {
    this.fetch();
  }

  private async fetch() {
    try {
      const { data } = await axios.get(`${process.env.VUE_APP_URL}api/artbook/item/${this.$route.params.id}`);
      this.artBook = data;
    } catch (err) {
      alert('데이터 불러오기 실패');
    }
  }
}
