





















































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { ObjectItemType } from '@type/object';
import axios from 'axios';

@Component({
  components: {
  },
})

export default class ObjectItem extends Vue {
  private object: ObjectItemType = null;

  private async created() {
    await this.fetch();
  }

  private async fetch() {
    try {
      const { data } = await axios.get(`${process.env.VUE_APP_URL}api/object/item/${this.$route.params.id}`);
      this.object = data;
    } catch (err) {
      alert('데이터 불러오기 실패');
    }
  }
}
